import './Satisfaction.css';
import React, { Component } from "react";
import Bandeau from "../assets/header_Pulmon'@ir_desktop.png"
import BandeauMobile from "../assets/header_Pulmon'@ir_mobile.png"
import axios from 'axios';

const API = "https://satisfactionpulmonair.com/api"

export default class Satisfaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            q1: 0,
            q2: 0,
            q3a: 0,
            q3b: 0,
            q4: 0,
            q5: 0,
            q6: 0,
            q7: "",
            error: "",
            send: false,
            received: false,
            windowsWidth: 0
        }
        this.sendForm = this.sendForm.bind(this)
        this.error = this.error.bind(this)
        this.errorDisplay = this.errorDisplay.bind(this)
    }

    handleWindowSize = () => {
        this.setState({windowsWidth:window.innerWidth})
    }

    componentDidMount() {
        this.handleWindowSize();
        window.addEventListener('resize', this.handleWindowSize)
    }

    error = (err) => {
        this.setState({ error: err })
    }

    sendForm = () => {
        if (!this.state.send) {
            if (this.state.q1 === 0) {
                this.error("Merci de choisir un résultat valide à la question 1")
            }
            else if (this.state.q2 === 0) {
                this.error("Merci de choisir un résultat valide à la question 2")
            }
            else if (this.state.q3a === 0) {
                this.error("Merci de choisir un résultat valide à la question 3A")
            }
            else if (this.state.q3b === 0) {
                this.error("Merci de choisir un résultat valide à la question 3B")
            }
            else if (this.state.q4 === 0) {
                this.error("Merci de choisir un résultat valide à la question 4")
            }
            else if (this.state.q5 === 0) {
                this.error("Merci de choisir un résultat valide à la question 5")
            }
            else if (this.state.q6 === 0) {
                this.error("Merci de choisir un résultat valide à la question 6")
            }
            else {
                this.setState({ send: true })
                axios.post(
                    API + "/send",
                    {
                        data: this.state
                    }
                ).then((res) => {
                    this.setState({ received: true })
                }).catch((err) => {
                    this.setState({ send: false });
                    this.error("Une erreur a eu lieu, merci de réessayer")
                    console.log(err)
                });
            }
        }
    }

    errorDisplay = () => {
        var interval = setInterval(() => {
            this.setState({ error: "" })
            clearInterval(interval)
        }, 3000)
        return (
            <div className='errorModal'>
                {this.state.error}
            </div>
        )
    }
    validationDisplay = () => {
        if (this.state.send) {
            if (this.state.received) {
                return (
                    <div className='validationBlack'>
                        <div className='validationModal'>
                            Merci de votre réponse, à bientôt
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className='validationBlack'>
                        <div className='validationModal'>
                            Votre réponse est en cours d'envoi
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.error !== "" ? this.errorDisplay() : null}
                {this.state.send ? this.validationDisplay() : null}
                <div>
                    <img className='bandeau' style={{ width: "100%" }} src={this.state.windowsWidth<=500?BandeauMobile:Bandeau} />
                </div>
                <div className='dualBlock'>
                    <div style={{ marginLeft: 20, marginBottom: 15 }}>
                        <div><b>1. D’une manière générale, quelle note attribueriez-vous à cette réunion ?</b> (1: note la plus basse / 10 : note la plus haute)</div>
                    </div>
                    <div className='questionBlock'>
                        <div className='Button' onClick={() => this.setState({ q1: 0 })} style={this.state.q1 === 0 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            0
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 1 })} style={this.state.q1 === 1 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            1
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 2 })} style={this.state.q1 === 2 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            2
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 3 })} style={this.state.q1 === 3 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            3
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 4 })} style={this.state.q1 === 4 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            4
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 5 })} style={this.state.q1 === 5 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            5
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 6 })} style={this.state.q1 === 6 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            6
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 7 })} style={this.state.q1 === 7 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            7
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 8 })} style={this.state.q1 === 8 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            8
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 9 })} style={this.state.q1 === 9 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            9
                        </div>
                        <div className='Button' onClick={() => this.setState({ q1: 10 })} style={this.state.q1 === 10 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            10
                        </div>
                    </div>
                    <div>
                        <div style={{ marginLeft: 20, marginBottom: 15, marginTop: 15 }}>
                            <div><b>2. Comment qualifieriez-vous le format de cet évènement ?</b> (horaires/dates)</div>
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q2-1"
                                name="q2"
                                value={1}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q2: e.target.value
                                    })
                                }}
                            />
                            1- Très inadapté
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q2-2"
                                name="q2"
                                value={2}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q2: e.target.value
                                    })
                                }}
                            />
                            2- Inadapté
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q2-3"
                                name="q2"
                                value={3}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q2: e.target.value
                                    })
                                }}
                            />
                            3- Neutre
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q2-4"
                                name="q2"
                                value={4}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q2: e.target.value
                                    })
                                }}
                            />
                            4- Adapté
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q2-5"
                                name="q2"
                                value={5}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q2: e.target.value
                                    })
                                }}
                            />
                            5- Très adapté
                        </div>
                    </div>
                    <div>
                        <div style={{ marginLeft: 20, marginBottom: 5, marginTop: 15 }}>
                            <div><b>3. Sur cet évènement, comment jugeriez-vous :</b></div>
                        </div>
                        <div>
                            <div className='leftQuestion3'>
                                <div><b>A. la pertinence du contenu partagé lors de cet évènement ?</b></div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3a-1"
                                        name="q3a"
                                        value={1}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3a: e.target.value
                                            })
                                        }}
                                    />
                                    1- Très insatisfaisant
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3a-2"
                                        name="q3a"
                                        value={2}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3a: e.target.value
                                            })
                                        }}
                                    />
                                    2- Insatisfaisant
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3a-3"
                                        name="q3a"
                                        value={3}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3a: e.target.value
                                            })
                                        }}
                                    />
                                    3- Neutre
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3a-4"
                                        name="q3a"
                                        value={4}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3a: e.target.value
                                            })
                                        }}
                                    />
                                    4- Satisfaisant
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3a-5"
                                        name="q3a"
                                        value={5}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3a: e.target.value
                                            })
                                        }}
                                    />
                                    5- Très satisfaisant
                                </div>
                            </div>
                            <div className='rightQuestion3'>
                                <div><b>B. le niveau d'expertise des orateurs ?</b></div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3b-1"
                                        name="q3b"
                                        value={1}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3b: e.target.value
                                            })
                                        }}
                                    />
                                    1- Très insatisfaisant
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3b-2"
                                        name="q3b"
                                        value={2}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3b: e.target.value
                                            })
                                        }}
                                    />
                                    2- Insatisfaisant
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3b-3"
                                        name="q3b"
                                        value={3}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3b: e.target.value
                                            })
                                        }}
                                    />
                                    3- Neutre
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3b-4"
                                        name="q3b"
                                        value={4}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3b: e.target.value
                                            })
                                        }}
                                    />
                                    4- Satisfaisant
                                </div>
                                <div className='radioBlock'>
                                    <input
                                        type="radio"
                                        id="q3b-5"
                                        name="q3b"
                                        value={5}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                q3b: e.target.value
                                            })
                                        }}
                                    />
                                    5- Très satisfaisant
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dualBlock' style={{ verticalAlign: "top" }}>
                    <div>
                        <div style={{ marginLeft: 20, marginBottom: 15, marginTop: 15 }}>
                            <div><b>4. Comment jugeriez-vous le niveau d'interaction avec les orateurs et les participants lors de cet évènement ?</b></div>
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q4-1"
                                name="q4"
                                value={1}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q4: e.target.value
                                    })
                                }}
                            />
                            1- Très insatisfaisant
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q4-2"
                                name="q4"
                                value={2}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q4: e.target.value
                                    })
                                }}
                            />
                            2- Insatisfaisant
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q4-3"
                                name="q4"
                                value={3}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q4: e.target.value
                                    })
                                }}
                            />
                            3- Neutre
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q4-4"
                                name="q4"
                                value={4}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q4: e.target.value
                                    })
                                }}
                            />
                            4- Satisfaisant
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q4-5"
                                name="q4"
                                value={5}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q4: e.target.value
                                    })
                                }}
                            />
                            5- Très satisfaisant
                        </div>
                    </div>
                    <div style={{ marginLeft: 20, marginBottom: 15, marginTop: 15 }}>
                        <div><b>5. Recommanderiez-vous ce type d’évènement à vos confrères ?</b> (1: Absolument pas / 10 : Oui absolument)</div>
                    </div>
                    <div className='questionBlock'>
                        <div className='Button' onClick={() => this.setState({ q5: 0 })} style={this.state.q5 === 0 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            0
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 1 })} style={this.state.q5 === 1 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            1
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 2 })} style={this.state.q5 === 2 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            2
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 3 })} style={this.state.q5 === 3 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            3
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 4 })} style={this.state.q5 === 4 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            4
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 5 })} style={this.state.q5 === 5 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            5
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 6 })} style={this.state.q5 === 6 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            6
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 7 })} style={this.state.q5 === 7 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            7
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 8 })} style={this.state.q5 === 8 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            8
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 9 })} style={this.state.q5 === 9 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            9
                        </div>
                        <div className='Button' onClick={() => this.setState({ q5: 10 })} style={this.state.q5 === 10 ? { backgroundColor: "rgba(249,174,1,0.5)" } : {}}>
                            10
                        </div>
                    </div>

                    <div>
                        <div style={{ marginLeft: 20, marginBottom: 15, marginTop: 15 }}>
                            <div><b>6. Comment qualifieriez-vous l’évènement comparé à ceux organisés par d’autres laboratoires pharmaceutiques ?</b></div>
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q6-1"
                                name="q6"
                                value={1}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q6: e.target.value
                                    })
                                }}
                            />
                            1- Très inférieur
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q6-2"
                                name="q6"
                                value={2}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q6: e.target.value
                                    })
                                }}
                            />
                            2- Inférieur
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q6-3"
                                name="q6"
                                value={3}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q6: e.target.value
                                    })
                                }}
                            />
                            3- Identique
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q6-4"
                                name="q6"
                                value={4}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q6: e.target.value
                                    })
                                }}
                            />
                            4- Supérieur
                        </div>
                        <div className='radioBlock'>
                            <input
                                type="radio"
                                id="q6-5"
                                name="q6"
                                value={5}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    this.setState({
                                        q6: e.target.value
                                    })
                                }}
                            />
                            5- Très supérieur
                        </div>
                    </div>
                    <div style={{ marginLeft: 20, marginBottom: 5, marginTop: 15 }}>
                        <div><b>7. Quelle(s) thématique(s) souhaiteriez-vous voir abordée(s) lors des prochains webinars ?</b></div>
                        <textarea
                        className='textArea'
                            value={this.state.q7}
                            style={{ width: "50%", height: 80, marginTop: 10 }}
                            onChange={(e) => {
                                this.setState({ q7: e.target.value })
                            }}
                        />
                    </div>
                    <div className='ButtonValidate' onClick={() => this.sendForm()}>
                        Envoyer
                    </div>
                    <div className='space'></div>
                </div>
            </div>)
    }
}