import './App.css';
import Satisfaction from "./Component/Satisfaction"

function App() {
  return (
    <Satisfaction />
  );
}

export default App;
